<template>
  <div>
    <b-modal
        hide-footer
        id="modal-prevent-closing"
        ref="my-modal"
        :title="`Editar cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >

      <ModalAppointment v-if="id_apoi" :id_appoinment="id_apoi"  :modal_close="closeModalReload"/>

    </b-modal>
    <b-modal
        hide-footer
        id="modal-appointment"
        ref="my-modal"
        :title="`Crear cita`"
        ok-title="Guardar"
        cancel-title="Cerrar"
        cancel-variant="outline-secondary"
        @show="resetModal"
        @hidden="resetModal"
        size="xl"
    >
      <ModalCreateAppointmentPlaces :date="dateSelect" :doc="doctSelect" :typeAppointment="typeAppointment" :closeModal="closeModalReload"/>
    </b-modal>
    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
        >
          <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
            <div class="p-2">
              <router-link v-if="getUser.appointee_id" to="/nueva/tratamiento/doctor">
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    aria-controls="sidebar-add-new-event"

                    variant="primary"
                    block

                >
                  Agregar Cita Tratamiento
                </b-button>
              </router-link>

                <b-button @click="addAppointment"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    aria-controls="sidebar-add-new-event"

                    variant="primary"
                    block

                >
                  Agregar Cita Tratamiento
                </b-button>
              <b-form-group label="Paciente" label-for="h-number">
                <b-form-input
                    @keyup="findCalendarPlacesSearchCompo"
                    v-model="search_appointment"
                    id="colony"
                    placeholder="Paciente"
                />
              </b-form-group>
              <h5 class="app-label section-label mb-1">
                <span class="align-middle">Calendario</span>
              </h5>
              <div class="list-states">
                <b-form-group v-if="!getUser.appointee_id">
                  <b-form-group>
                    <b-form-checkbox-group
                        v-model="selectedStates"
                        name="event-filter"
                        stacked
                        @change="emitStatesSelect"
                    >
                      <b-form-checkbox
                          :disabled="checkStatus"
                          v-for="item in dataStates"
                          :key="item"
                          :id="`a${item}`"
                          name="event-filter"
                          :value="item"
                          class="mb-1"
                          :class="`custom-control-${item}`"
                      >
                        {{ changeTextSpanish(item) }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>
                  </b-form-group>
                </b-form-group>
              </div>
              <div class="list-docts">
                <h2>Salas</h2>

                <b-form-group v-if="!getUser.appointee_id">
                  <v-select
                      v-model="selected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="value"
                      @change="selectDoctor()"
                      :options="doctors"
                  />
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox-group
                      v-model="selectedCalendars"
                      name="event-filter"
                      stacked
                      @change="emitStatesSelect"
                  >
                    <b-form-checkbox
                        :disabled="checkStatus"
                        v-for="item in places"
                        :key="item.id"
                        :id="`a${item.id}`"
                        name="event-filter"
                        :value="item.id"
                        class="mb-1"
                        :class="`custom-control-${item.color}`"
                    >
                    <span :style="`background-color: ${item.color}; width: 20px; height: 20px; color: transparent`">
                      er
                    </span>
                      {{ item.label }}


                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                  class="full-calendar"
                  ref="fullCalendar"
                  :options="calendarOptions"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import moment from 'moment'
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import esLocale from "@fullcalendar/core/locales/es";
import {mapActions, mapGetters} from "vuex";
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select'
import ModalAppointment from "@/components/Appointments/ModalAppointment";
import ModalCreateAppointmentPlaces from "@/components/Appointments/ModalCreateAppointmentPlaces";
import {
  BAvatar,
  BBadge, BButton, BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup, BInputGroupAppend, BOverlay,
  BPagination,
  BRow,
  BTable, VBTooltip,
  BPopover, BFormCheckbox, BFormCheckboxGroup
} from "bootstrap-vue";
import io from "socket.io-client";
import {urlSocket} from "@/sockets/socket";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ModalCreateAppointment from "@/components/Appointments/ModalCreateAppointment.vue";
import {awrap} from "vue-qrcode-reader";
import {HOURS_RESTART} from "@/store/config";

export default {
  name: "NewCalendarPlaces",
  components: {
    ModalCreateAppointment,
    FullCalendar,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    VBTooltip,
    BPopover, BFormCheckbox, BFormCheckboxGroup, vSelect,
    ModalAppointment,ModalCreateAppointmentPlaces
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple
  },
  data() {
    return {
      socket : io(urlSocket),
      typeAppointment: 'tratamiento',
      startDate: null,
      endDate: null,
      chargeModal: false,
      dateSelect: null,
      doctSelect: null,
      checkStatus:false,
      id_apoi: null,
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
        initialView: 'timeGridWeek',
        slotMinTime:"07:00:00",
        slotMaxTime:"23:00:00",
        locale: esLocale,
        headerToolbar: {
          start: 'sidebarToggle, prev,next, title',
          end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
        },
        selectable: true,
        events: [],
        dateClick: this.handleDateClick,
        eventClick: this.updateAppointment,
        eventDidMount: this.renderFunction,
        datesSet: this.handleDatesSet
      },
      all_doctor: true,
      places: [],
      search_appointment:null,
      doctors: [],
      appointee_id: null,
      selectedCalendars: [],
      selectedStates: ['new','approved','pending'],
      dataStates: ['new','approved','rejected','pending'],
      selected: {id: "", value: "Todos"},
      isHandlingDates: false,
    };
  },
  async created() {
    if (this.getUser.appointee_id) {
      this.appointee_id = this.getUser.appointee_id;
      await this.placesArray();
    } else {
      await this.doctorsArray();
      await this.placesArray();
    }



    await this.initialize(this.selectedCalendars);
  },
  mounted() {
    this.$root.$on('closeComponent', async() => {
      // your code goes here
      await this.closeModal()
    })
    // this.socket.on('LIST_TREATMENT', async(data) => {
    //   if (this.getUser.appointee_id) {
    //     this.appointee_id = this.getUser.appointee_id
    //     await this.placesArray()
    //   } else {
    //     await this.doctorsArray()
    //     await this.placesArray();
    //
    //   }
    //   if (this.selectedStates.length > 0){
    //     await this.emitStatesSelect()
    //   }else{
    //     await this.initialize(this.selectedCalendars)
    //   }
    //
    // });
  },
  methods: {
    ...mapActions('calendar_new', ['findCalendarPlaces', 'findAppointment','findCalendarPlacesSearch','findCalendarStates']),
    ...mapActions('appointment', ['findAppointmentDoctorPlaceAppoi']),
    resetModal() {
    },
    async handleDatesSet(arg) {

      if (this.isHandlingDates) return;


      this.isHandlingDates = true;


      if (!this.startDate || !this.endDate || this.startDate !== arg.startStr || this.endDate !== arg.endStr) {
        this.startDate = arg.startStr;
        this.endDate = arg.endStr;
        this.calendarOptions.events = [];
        await this.initialize(this.selectedCalendars);
      }


      this.isHandlingDates = false;
    },
    async selectDoctor() {
      this.appointee_id = this.selected.id;
      await this.initialize(this.selectedCalendars)
    },
    async checkAll(type) {
      if (type) {
        this.all_doctor = type
        await this.placesArray()
      } else {
        this.all_doctor = type
        this.selectedCalendars = []
        return
      }

    },
    async emitPlaces() {
      await this.initialize(this.selectedCalendars)

    },
    async emitStatesSelect() {
      this.checkStatus = true
      let sendData;
      if (this.selectedStates.length <= 0) {
        sendData = 'empty';
      }else{
        sendData = this.selectedStates
      }
      this.calendarOptions.events = []
      const response = await this.findCalendarStates({data: this.selectedCalendars, appointee_id: this.appointee_id,states:sendData,startDate:this.startDate, endDate: this.endDate})


      for (const a of response) {
        const date1 = moment(a.app_dt_start).subtract(HOURS_RESTART, 'hours').format('YYYY-MM-DD HH:mm:ss')
        const date2 = moment(a.app_dt_stop).subtract(HOURS_RESTART, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.calendarOptions.events.push({
          id: a.id,
          title: a.ResPartner.name,
          color: a.ResPartnerAppointment.color_hexa,
          start: date1,
          end: date2,
          extendedProps: {
            guests: {
              doctor_id: a.ResPartnerAppointment.id,
              reference: a.name,
              description: a.description,
              name_doctor: a.ResPartnerAppointment.name,
            },
            calendar: a.ResPartnerAppointment.name,
            doctor: a.ResPartnerAppointment.id,

          },
        })
      }
      this.checkStatus = false
      return


    },
    async doctorsArray() {
      const response = await this.findAppointment()
      this.doctors = []
      this.doctors.push({
        value: 'Todos',
        id: '',
      })
      for (const a of response) {
        this.doctors.push({
          value: a.ResPartnerAppointment.name,
          id: a.ResPartnerAppointment.id,
        })
      }
    },
    async placesArray() {
      const response = await this.findAppointmentDoctorPlaceAppoi()
      this.places = []
      this.selectedCalendars = []

      for (const a of response) {
        this.places.push({
          color: a.ResPartnerAppointment.color_hexa,
          label: a.ResPartnerAppointment.name,
          id: a.ResPartnerAppointment.id,
        })
        this.selectedCalendars.push(a.ResPartnerAppointment.id)
      }
    },
    async initialize(data) {
      this.calendarOptions.events = []
      if (data.length <= 0) {
        this.calendarOptions.events = []
        return
      }
      this.calendarOptions.events = []

      const response = await this.findCalendarPlaces({data: data, appointee_id: this.appointee_id,startDate: this.startDate, endDate: this.endDate})
      for (const a of response) {
        const date1 = moment(a.app_dt_start).subtract(HOURS_RESTART, 'hours').format('YYYY-MM-DD HH:mm:ss')
        const date2 = moment(a.app_dt_stop).subtract(HOURS_RESTART, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.calendarOptions.events.push({
          id: a.id,
          title: a.ResPartner.name,
          color: a.ResPartnerAppointment.color_hexa,
          start: date1,
          end: date2,
          extendedProps: {
            guests: {
              doctor_id: a.ResPartnerAppointment.id,
              reference: a.name,
              description: a.description,
              name_doctor: a.ResPartnerAppointment.name,
            },
            calendar: a.ResPartnerAppointment.name,
            doctor: a.ResPartnerAppointment.id,

          },
        })
      }
    },
    async findCalendarPlacesSearchCompo() {
      this.calendarOptions.events = []
      if (!this.search_appointment){
        return await this.initialize(this.selectedCalendars)
      }
      const response = await this.findCalendarPlacesSearch({data: this.selectedCalendars, appointee_id: this.appointee_id, search:this.search_appointment})
      this.calendarOptions.events = []
      for (const a of response) {
        const date1 = moment(a.app_dt_start).subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        const date2 = moment(a.app_dt_stop).subtract(5, 'hours').format('YYYY-MM-DD HH:mm:ss')
        this.calendarOptions.events.push({
          id: a.id,
          title: a.ResPartner.name,
          color: a.ResPartnerAppointment.color_hexa,
          start: date1,
          end: date2,
          extendedProps: {
            guests: {
              doctor_id: a.ResPartnerAppointment.id,
              reference: a.name,
              description: a.description,
              name_doctor: a.ResPartnerAppointment.name,
            },
            calendar: a.ResPartnerAppointment.name,
            doctor: a.ResPartnerAppointment.id,

          },
        })
      }
    },
    async closeModalReload(){
      this.doctSelect = null;
      this.dateSelect = null;
      await this.$bvModal.hide('modal-appointment');
      await this.$bvModal.hide('modal-prevent-closing');
      if (this.getUser.appointee_id) {

        this.appointee_id = this.getUser.appointee_id
        await this.placesArray()
        await this.initialize(this.selectedCalendars)
      } else {
        await this.doctorsArray()
        await this.placesArray()
        await this.initialize(this.selectedCalendars)
      }
    },
    async handleDateClick(arg) {
      let doct;
      if (this.getUser.appointee_id) {
        doct = 'doctor'
      } else {
        doct = 'recepcion'
      }
      this.doctSelect = doct;
      this.dateSelect = arg.date;

      await this.$bvModal.show('modal-appointment')
    },
    async addAppointment() {
      this.dateSelect = null;
      await this.$bvModal.show('modal-appointment')
    },
    async updateAppointment(info) {
      const id = info.event._def.publicId
      this.id_apoi = parseInt(id)
      await this.$bvModal.show('modal-prevent-closing')

    },
    async closeModal() {
      this.id_apoi = null
      await this.$bvModal.hide('modal-prevent-closing')
      await this.initialize(this.selectedCalendars)
    },
    renderFunction(info) {

      const event_start = moment(info.event.start).format('HH:mm');
      const event_end = moment(info.event.end).format('HH:mm');
      let titleStr = 'xxxx'
      let contentStr = 'xxxx'
      const html_view = `
           Horario de cita:    ${event_start} - ${event_end},
           Medico o sala: ${info.event._def.extendedProps.guests.name_doctor}
            Observaciones: ${info.event._def.extendedProps.guests.description}
   `
      new BPopover({
        propsData: {
          container: 'body',
          html: true,
          title: info.event._def.title,
          content: `${html_view}`,
          triggers: 'hover',
          target: info.el,
        }
      }).$mount()
    }
  },
  computed: {
    ...mapGetters('auth', ['getUser']),
    cssVars(color) {
      return {
        '--bg-color': color,
      }
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .list-docts {
  padding: 12px !important;
}

.vs__clear {
  display: none !important;
}
[dir]  .list-states{
  height: 200px;
  padding: 20px;
}

</style>
